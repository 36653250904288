var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    {
      scopedSlots: _vm._u([
        {
          key: "content",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
        {
          key: "extraContent",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { title: "平台开发文档" },
                    },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "large",
                              "font-weight": "500",
                            },
                          },
                          [_vm._v("前置URL:")]
                        ),
                        _c("span", { staticStyle: { color: "#d14" } }, [
                          _vm._v(
                            _vm._s(_vm.protocol) +
                              "//" +
                              _vm._s(_vm.hostname) +
                              "/api"
                          ),
                        ]),
                        _c("p", { staticStyle: { "font-size": "small" } }, [
                          _vm._v("(下文以api_url表示前置url)"),
                        ]),
                      ]),
                      _c("a-divider"),
                      _c(
                        "div",
                        { staticClass: "markdown-body" },
                        [_c("open-api-docs")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showToTop,
                  expression: "showToTop",
                },
              ],
              staticClass: "goTop",
              on: { click: _vm.toTop },
            },
            [
              _c("img", {
                staticClass: "img",
                attrs: { src: require("@/assets/images/toTop.png"), alt: "" },
              }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }