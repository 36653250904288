<!--
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-07-07 17:49:36
-->
<template>
  <page-header-wrapper>
    <template v-slot:content> </template>
    <template v-slot:extraContent> </template>
    <div>
      <a-row :gutter="24">
        <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <!-- 项目简介 -->
          <a-card style="width:100%" title="平台开发文档">
            <div>
              <span style="font-size:large; font-weight:500;">前置URL:</span>
              <span style="color:#d14;">{{ protocol }}//{{ hostname }}/api</span>
              <p style="font-size: small;">(下文以api_url表示前置url)</p>
            </div>
            <a-divider />
            <div class="markdown-body">
              <open-api-docs />
            </div>
          </a-card>
        </a-col>
      </a-row>
      <div class="goTop" v-show="showToTop" @click="toTop">
        <img class="img" :src="require('@/assets/images/toTop.png')" alt="" />
      </div>
    </div>
  </page-header-wrapper>
</template>

<script>
import OpenApiDocs from './markdown/tenant.md'
import 'highlight.js/styles/github.css'
import 'github-markdown-css/github-markdown-light.css'
// import './css/github-markdown.css'
import { PageHeaderWrapper } from '@/components/MyLayout'

export default {
  name: 'Index',
  components: {
    PageHeaderWrapper,
    OpenApiDocs
  },
  data() {
    return {
      client: {},
      clientSecret: null,
      protocol: '',
      hostname: '',
      showToTop: false
    }
  },
  computed: {},
  created() {
    this.protocol = location.protocol
    this.hostname = location.hostname
  },
  mounted() {
    const that = this
    window.onscroll = function() {
      // 让谁进行滚动 body html（有的浏览器获取不到body或html），所以做一个兼容的方式
      // scrollTop据顶部的滑动的距离
      const res = document.body.scrollTop || document.documentElement.scrollTop
      if (res >= 400) {
        // 当大于400px，按钮出现
        that.showToTop = true
      } else {
        that.showToTop = false
      }
    }
  },
  methods: {
    toTop() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="less" scoped>
.goTop {
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 50%;
  .img {
    width: 60px;
    height: auto;
  }
}
.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
}
</style>
